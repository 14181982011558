import { useTranslation } from "react-i18next";

import styles from "./NotificationSettings.module.scss";

import Toggle from "../Toggle";
import { NotificationSettingsProps } from "./NotificationSettings.types";

/**
 * Renders the card for with notification settings
 * @param props React component properties
 * @returns JSX element
 */
const NotificationSettings = (
  props: NotificationSettingsProps
): JSX.Element => {
  const { t } = useTranslation("translation");
  return (
    <div className={styles.container}>
      <h2 className={styles.h2}>
        {t("settings.notificationSettings.description")}
      </h2>
      <Toggle
        isChecked={props.isChecked}
        isLoading={props.isLoading}
        label={
          props.isChecked
            ? t("settings.notificationSettings.enabled")
            : t("settings.notificationSettings.disabled")
        }
        onChange={e => props.onChange(e.target.checked)}
      />
    </div>
  );
};

export default NotificationSettings;
