// TODO This component will be removed once the Document history and Document Saved tables migrated to V8
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import { Virtuoso } from "react-virtuoso";
import cn from "classnames";

import styles from "./Table.module.scss";

import Condition from "../../shared/Condition";
import Icon from "../Icon";
import PaginationNumbers from "../Pagination";
import SelectCheckbox from "../Select&chekbox/SelectChekbox";

export interface Props {
  className?: string;
  isWhitePagination?: boolean;
  defaultPage?: number;
  defaultPageSize?: number;
  size?: "sm" | "md" | "lg";
  columns: any[];
  data: any[] | null | undefined;
  defaultSortBy?: { id: string; desc: boolean }[];
  onSort?: (props: { sortBy: any }) => void;
  fetchData?: (paginationParams: { take?: number; skip?: number }) => void;
  totalItems?: any;
  onRowClick?: (row: unknown, e: any, id: number) => void;
  hidePagination?: boolean;
  hiddenColumns?: string[];
  callapsComponent?: any;
  callapsClassName?: any;
  onChangeSize?: any;
  loading?: boolean;
  canDelete?: boolean;
  selectActionType?: any;
  selectActionLabel?: any;
}

const Table: React.FC<Props> = ({
  loading,
  className,
  data,
  columns,
  size,
  onSort,
  defaultPageSize = 10,
  fetchData,
  defaultPage,
  onRowClick = () => ({}),
  onChangeSize = () => ({}),
  totalItems,
  selectActionType,
  selectActionLabel,
  hidePagination = true,
  isWhitePagination = false,
  canDelete = true
}) => {
  const { control } = useForm();
  const [pageCount, setPageCount] = useState(0);

  const options = [
    { label: "Select All", value: "SelectAll" },
    { label: "Deselect All", value: "DeselectAll" },
    // { label: "Delete Selected", value: "selectAction" }
    { label: selectActionType, value: selectActionLabel }
  ];

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30
    }),
    []
  );
  const Skeleton = () => {
    return <div className={cn(styles.skeleton)} />;
  };
  const tableData = React.useMemo(() => (loading ? Array(5).fill({}) : data), [
    loading,
    data
  ]);
  const tableColumns = React.useMemo(
    () =>
      loading
        ? columns.map(column => ({
            ...column,
            Cell: column.Header ? <Skeleton /> : ""
          }))
        : columns,
    [loading, columns]
  );
  const tableProps = useTable(
    {
      columns: tableColumns,
      data: tableData as Record<string, any>[],
      pageCount,
      defaultColumn,
      initialState: {
        pageIndex: defaultPage ?? 0
      },
      manualPagination: true,
      manualSortBy: !!onSort,
      autoResetPage: false
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useFlexLayout,

    (hooks: any) => {
      canDelete
        ? hooks.visibleColumns.push((columns: any[]) => [
            {
              id: "selection",
              withoutSort: "false",
              width: 55,
              Header: ({ getToggleAllRowsSelectedProps, ...rest }: any) => (
                <SelectCheckbox
                  onChange={getToggleAllRowsSelectedProps().onChange}
                  CustomInput={() => {
                    return (
                      <div className={styles.customInput}>
                        <input
                          className={styles.checkbox}
                          type="checkbox"
                          {...getToggleAllRowsSelectedProps()}
                        />
                      </div>
                    );
                  }}
                  selectedFlatRows={rest.selectedFlatRows}
                  control={control}
                  name={"uploadType"}
                  placeholder={""}
                  options={options}
                />
              ),
              Cell: ({ row }: any) => (
                <div>
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    {...row.getToggleRowSelectedProps()}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  />
                </div>
              )
            },
            ...columns
          ])
        : null;
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    gotoPage,
    state: { pageIndex, pageSize, sortBy }
  } = tableProps;

  useEffect(() => {
    if (defaultPage === 0) {
      gotoPage(defaultPage);
    }
  }, [defaultPage]);

  useEffect(() => {
    gotoPage(defaultPage ?? 0);
    setPageCount(Math.ceil(totalItems / defaultPageSize));
  }, [totalItems, pageSize, defaultPageSize]);

  useEffect(() => {
    gotoPage(defaultPage ?? 0);
    onSort && onSort({ sortBy });
  }, [sortBy]);

  const handleRowClick = (
    event: React.MouseEvent,
    id: number,
    row: unknown
  ) => {
    const selection = window?.getSelection()?.toString() ?? "";
    if (selection.length > 0) {
      event.preventDefault();
    } else {
      onRowClick(row, event, id);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.tableContainer}>
          <table
            {...getTableProps()}
            className={cn(styles.table, styles[size as string], className)}
          >
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      key={column.id}
                      {...column.getHeaderProps(
                        !column?.withoutSort && column.getSortByToggleProps()
                      )}
                      className={column?.headerClass}
                    >
                      {column.render("Header")}
                      {!column?.withoutSort && (
                        <button title="">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon kind="sort_down" />
                            ) : (
                              <Icon kind="sort_up" />
                            )
                          ) : (
                            <Icon kind="sort" />
                          )}
                        </button>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {data?.length || loading ? (
                <Virtuoso
                  className={styles.virtuoso}
                  data={rows}
                  totalCount={rows.length}
                  initialItemCount={rows.length}
                  itemContent={(_, row) => {
                    if (!row) return null;
                    prepareRow(row);
                    return (
                      <div className={styles.row} key={row.original.id}>
                        <tr
                          {...row.getRowProps()}
                          onClick={event =>
                            handleRowClick(event, row.original.id, row)
                          }
                          onMouseLeave={() => {
                            if (row?.original?.setLeft) {
                              row.original.setLeft(0);
                            }
                          }}
                        >
                          {row.cells.map((cell: any) => {
                            return (
                              <td
                                key={cell.id}
                                className={cell.column.className}
                                {...cell.getCellProps()}
                              >
                                <p>{cell.render("Cell")}</p>
                              </td>
                            );
                          })}
                        </tr>
                      </div>
                    );
                  }}
                />
              ) : null}

              {data && !data.length && !loading && (
                <div className={styles.noResult}>No results found</div>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Condition
        condition={!hidePagination && !!pageCount}
        Truthy={
          <div className={styles.paginationWrap}>
            <PaginationNumbers
              changePageRequest={(page: any) => {
                gotoPage(page);
                if (fetchData) {
                  fetchData({
                    skip: page * defaultPageSize,
                    take: defaultPageSize
                  });
                }
              }}
              totalAmount={pageCount}
              currentPage={pageIndex + 1}
              isWhite={isWhitePagination}
              onChangeSize={(e: any) => {
                gotoPage(defaultPage ?? 0);
                onChangeSize(e);
              }}
              size={defaultPageSize}
            />
          </div>
        }
      />
    </>
  );
};

export default Table;
