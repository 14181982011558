import React from "react";
import { Virtuoso } from "react-virtuoso";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import cn from "classnames";

import styles from "./UploadTable.module.scss";

import Condition from "../../shared/Condition";
import Icon from "../Icon";
import { selectionColumnStyles } from "./UploadTable.constants";
import { UploadTableProps } from "./UploadTable.types";

const UploadTable = <TData, TValue>({
  loading,
  className,
  data,
  columns,
  size = "md",
  onRowClick = () => ({}),
  ...rest
}: UploadTableProps<TData, TValue>) => {
  const Skeleton = () => {
    return <div className={cn(styles.skeleton)} />;
  };

  const defaultColumnSizing = {
    size: 200,
    minSize: 30,
    maxSize: 400
  };

  const tableData = React.useMemo(() => (loading ? Array(5).fill({}) : data), [
    loading,
    data
  ]);
  tableData;

  const columnsWithLoading = React.useMemo(
    () =>
      loading
        ? columns.map(column => ({
            ...column,
            cell: () => <Skeleton />
          }))
        : columns,
    [loading, columns]
  );

  const table = useReactTable<TData>({
    ...rest,
    columns: columnsWithLoading,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: defaultColumnSizing,
    getRowId: (row: TData) => (row as any).id
  });

  const rows = table.getRowModel().rows;

  const handleRowClick = (
    event: React.MouseEvent,
    id: number,
    row: unknown
  ) => {
    const selection = window?.getSelection()?.toString() ?? "";
    if (selection.length > 0) {
      event.preventDefault();
    } else {
      onRowClick(row, event, id);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.tableContainer}>
        <table className={cn(styles.table, styles[size as string], className)}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    style={{
                      width: header.column.getSize(),
                      flex: `1 1 ${header.column.getSize()}px`,
                      ...(header.column.id === "selectionColumn"
                        ? selectionColumnStyles
                        : {})
                    }}
                    key={header.id}
                    className={styles.headerClass}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div
                      className={styles.header}
                      title={
                        header.column.getCanSort()
                          ? header.column.getNextSortingOrder() === "asc"
                            ? "Sort ascending"
                            : header.column.getNextSortingOrder() === "desc"
                            ? "Sort descending"
                            : "Clear sort"
                          : undefined
                      }
                    >
                      <p>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </p>
                      <Condition
                        condition={header.column.getCanSort()}
                        Truthy={
                          {
                            asc: <Icon kind="sort_up" />,
                            desc: <Icon kind="sort_down" />
                          }[header.column.getIsSorted() as string] ?? (
                            <Icon kind="sort" />
                          )
                        }
                      />
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {data?.length || loading ? (
              <Virtuoso
                className={styles.virtuoso}
                data={rows}
                totalCount={rows.length}
                initialItemCount={rows.length}
                itemContent={(_, row) => {
                  if (!row) return null;
                  return (
                    <div className={styles.row} key={row.id}>
                      <tr onClick={event => handleRowClick(event, 5, row)}>
                        {row.getVisibleCells().map(cell => {
                          return (
                            <td
                              style={{
                                width: cell.column.getSize(),
                                flex: `1 1 ${cell.column.getSize()}px`,
                                ...(cell.column.id === "selectionColumn"
                                  ? selectionColumnStyles
                                  : {})
                              }}
                              key={cell.id}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    </div>
                  );
                }}
              />
            ) : null}

            {data && !data.length && !loading && (
              <div className={styles.noResult}>No results found</div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UploadTable;
