import styles from "./InfoItem.module.scss";

import { InfoItemProps } from "./InfoItem.types";

/**
 * Renders settings info field
 * @param props React component properties
 * @returns JSX element
 */
const InfoItem = (props: InfoItemProps): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.label}>{props.label}</div>
    {props.value && <div>{props.value}</div>}
  </div>
);

export default InfoItem;
