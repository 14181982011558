import React from "react";
import cn from "classnames";

import styles from "./Toggle.module.scss";

import { ToggleProps } from "./Toggle.types";

const Toggle: React.FC<ToggleProps> = ({
  isChecked,
  onChange,
  label,
  isLoading
}) => {
  return (
    <label className={styles.toggle}>
      {label}
      <input
        onChange={onChange}
        className={cn({
          [styles.isLoading]: isLoading
        })}
        type="checkbox"
        disabled={isLoading}
        checked={isChecked}
      />
      <div className={styles.sliderContainer}>
        <span className={styles.slider} />
      </div>
    </label>
  );
};

export default Toggle;
