import React, { useState } from "react";
import cn from "classnames";

import styles from "./UploadList.module.scss";

import Condition from "../../../../components/shared/Condition";
import Icon from "../../../../components/shared/Icon";
import Button from "../../../../components/userManagement/WCMButton";
import { useActions } from "../../../../hooks/useActions";
import { UploadedFile } from "../../types";
type UploadItemProps = {
  file: UploadedFile;
  handleDelete: (id: string) => void;
  cancelUpload: (id: string) => void;
  percentage: number;
};

const UploadItem: React.FC<UploadItemProps> = ({
  file,
  cancelUpload,
  handleDelete,
  percentage
}) => {
  const { SetOpenModalAction } = useActions();
  const [isDeleting, setIsDeleting] = useState(false);
  const isValidating =
    file?.validationStatus === null &&
    file?.invalidationReason === null &&
    percentage === 100;
  const isPending = percentage === 0;
  const isValidated = file?.validationStatus === "VALID";
  const status = isValidated ? "success" : isPending ? "pending" : "uploading";

  const openFileCancelPopup = ({
    file,
    cancelUpload
  }: {
    file: any;
    cancelUpload: any;
  }) => {
    SetOpenModalAction({
      isOpen: true,
      type: "CancelUpload",
      queryParams: {
        file,
        cancelUploadById: cancelUpload
      }
    });
  };

  return (
    <div className={styles.uploadItem}>
      <p>{file.fileName}</p>
      <div
        className={cn(styles.statusWrapper, {
          [styles.isValidating]: isValidating,
          [styles.isDeleting]: isDeleting
        })}
      >
        <Condition
          condition={!!file.invalidationReason}
          Truthy={
            <div className={styles.statusWrapper}>
              <p className={styles.error}>{file.invalidationReason}</p>
              <Button
                isLoading={isDeleting}
                disabled={isDeleting}
                onClick={() => {
                  setIsDeleting(true);
                  handleDelete(file.id);
                }}
                variant="text"
                postIcon={{ kind: "bin" }}
              />
            </div>
          }
          Falsy={
            <>
              <Condition
                condition={status === "success"}
                Truthy={
                  <div className={styles.successWrapper}>
                    <Icon kind="success_circle" />
                  </div>
                }
              />
              <Condition
                condition={status === "uploading"}
                Truthy={
                  <>
                    <svg className="progress-ring" width="13" height="13">
                      <circle
                        className="progress-ring__circle"
                        strokeWidth="1"
                        fill="transparent"
                        strokeDashoffset={37.7 - (percentage / 100) * 37.7}
                        r="6"
                        cx="6.5"
                        cy="6.5"
                      />
                    </svg>
                    <Button
                      variant="text"
                      onClick={() => {
                        openFileCancelPopup({ cancelUpload, file });
                      }}
                      postIcon={{ kind: "x_3" }}
                    />
                  </>
                }
              />
              <Condition
                condition={status === "pending"}
                Truthy={<p className={styles.isPending}>Pending</p>}
              />
            </>
          }
        />
      </div>
    </div>
  );
};

export default UploadItem;
