import React from "react";
import ReactTooltip from "react-tooltip";
import { Virtuoso } from "react-virtuoso";
import axios from "axios";
import cn from "classnames";

import styles from "./DownloadData.module.scss";

import { useActions } from "../../../../../hooks/useActions";
import { oktaAuth } from "../../../../../lib/oktaAuth";
import {
  DocumentType,
  PayeeAccount
} from "../../../../../page/StatementUpload/types";
import { fileUtils } from "../../../../../page/StatementUpload/utils";
import Icon from "../../../Icon";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI
});

axiosInstance.interceptors.request.use(config => {
  config.headers.common[
    "Authorization"
  ] = `Bearer ${oktaAuth.getAccessToken()}`;
  config.headers.common["accept"] = "application/json";
  return config;
});

interface DataTooltipProps {
  id: string;
  content: string;
  place?: string;
}
const { getSignedURL } = fileUtils;

const DataTooltip: React.FC<DataTooltipProps> = ({ id, content, place }) => (
  <ReactTooltip
    id={id}
    data-id={id}
    border
    place={place || "top"}
    effect="solid"
    arrowColor="rgba(56, 56, 56, 1)"
    className={cn(styles.toolTip)}
  >
    {content}
  </ReactTooltip>
);

interface DataRowProps {
  index: number;
  payeeCode: string;
  id: number;
  fileName: string;
  handlePayeeClick?: (payeeCode: string, tableType: DocumentType) => void;
}

const DataRow: React.FC<DataRowProps> = ({
  index,
  id,
  payeeCode,
  fileName,
  handlePayeeClick
}) => {
  const initialTableType: DocumentType = localStorage.getItem(
    "uploadTableType"
  ) as DocumentType;
  return (
    <div className={styles.dataRow} key={payeeCode}>
      <div className={styles.index}>{index + 1}</div>
      <DataTooltip
        id={payeeCode}
        content={`Impersonate using payee code - ${payeeCode}`}
        place="right"
      />
      <div
        data-for={payeeCode}
        data-tip=""
        className={styles.payeeCode}
        onClick={() =>
          handlePayeeClick && handlePayeeClick(payeeCode, initialTableType)
        }
      >
        <span data-for={payeeCode} data-tip="">
          {payeeCode}
        </span>
      </div>
      <DataTooltip
        id={fileName}
        content={`Download - ${fileName}`}
        place="top"
      />
      <div
        onClick={() => {
          getSignedURL({
            documentId: id,
            tableType: initialTableType,
            name: fileName
          });
        }}
        data-for={fileName}
        data-tip=""
        className={styles.fileName}
      >
        {fileName}
      </div>
    </div>
  );
};

// api/payee-accounts/fetch

interface DownloadDataProps {
  rowData: {
    documents: {
      id: number;
      payeeCode: string;
      fileName: string;
    }[];
  };
  setLoading: (loading: boolean) => void;
}

const DownloadData: React.FC<DownloadDataProps> = ({ rowData, setLoading }) => {
  const { SetOpenModalAction } = useActions();
  const { documents } = rowData;
  const handleInpersonatePopupOpen = (user: any) => {
    const mappedUser = {
      ...user,
      isPayee: true
    };
    SetOpenModalAction({ isOpen: true, type: "impersonate", user: mappedUser });
  };
  const handlePayeeClick = async (
    payeeCode: string,
    documentType: DocumentType
  ) => {
    setLoading(true);
    let payeeAccount: PayeeAccount | null = null;
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URI}api/payee-accounts/associated-users`,
        [payeeCode]
      );
      payeeAccount = res.data[0];
    } finally {
      setLoading(false);
    }
    if (!payeeAccount?.users?.[0]) {
      return alert(`No user found with this payee code ${payeeCode}`);
    }
    const user = {
      ...payeeAccount.users[0],
      payeeCode: payeeAccount.payeeCode,
      documentType
    };
    handleInpersonatePopupOpen(user);
  };
  return (
    <div className={styles.payeeCodeListContainer}>
      <div className={styles.title}>
        <div>
          <Icon className={styles.fileIcon} kind="file" />
        </div>
        {`${documents.length} ${
          documents.length === 1 ? "File" : "Files"
        } Uploaded`}
      </div>
      <div className={styles.payeeCodeLists}>
        <div className={styles.dataHeader}>
          <div className={styles.index}>#</div>
          <div className={styles.payeeCodeHeader}>Payee Code</div>
          <div className={styles.fileNameHeader}>File Name</div>
        </div>
        <div className={styles.payeeCodeList}>
          <Virtuoso
            className={styles.virtuoso}
            data={documents}
            totalCount={documents.length}
            itemContent={(index, el) => (
              <DataRow
                key={index}
                index={index}
                id={el.id}
                payeeCode={el.payeeCode}
                fileName={el.fileName}
                handlePayeeClick={handlePayeeClick}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadData;
