import classNames from "classnames";

import styles from "./InfoItem.module.scss";

import { InfoItemProps } from "./InfoItem.types";

/**
 * Renders user profile info field
 * @param props React component properties
 * @returns JSX element
 */
const InfoItem = (props: InfoItemProps): JSX.Element => {
  const { className, label, value, variant = "normal" } = props;
  const labelClassName = classNames(
    styles.label,
    variant === "control" && styles.controlLabel
  );
  return (
    <div className={classNames(styles.container, className)}>
      <div className={labelClassName}>{label}</div>
      <div className={classNames(variant === "control" && styles.controlValue)}>
        {value || "-"}
      </div>
    </div>
  );
};

export default InfoItem;
