import React from "react";
import cn from "classnames";

import styles from "./TextAreaField.module.scss";
import { ReactComponent as WarningIcon } from "../../../icons/warning.svg";

export interface TextAreaProps extends React.PropsWithChildren<unknown> {
  className?: string;
  name: string;
  label?: string;
  errors?: any;
  limit?: number;
  ref?: any;
  isReservedErrorSpace?: boolean;

  [key: string]: any;
}

const TextAreaField: React.FC<TextAreaProps> = React.forwardRef(
  function TextAreaField(props, ref: any) {
    const {
      className,
      name,
      label,
      errors,
      limit = 160,
      isReservedErrorSpace = false,
      ...res
    } = props;

    return (
      <label
        htmlFor={name}
        className={`${styles.textArea_container} ${className}`}
      >
        <span className={styles.textArea_label}>{label}</span>
        <div>
          <div className={styles.textArea_wrapper}>
            <textarea
              placeholder=" "
              {...res}
              ref={ref || null}
              name={name}
              id={name}
              cols={30}
              rows={10}
              maxLength={limit}
              className={`${styles.textArea} ${errors[name] &&
                styles.textArea_inValid}`}
            />
            <span>
              <WarningIcon
                className={`${styles.textArea_icon_warning} ${styles.textArea_icon}`}
              />
              <span
                className={cn(styles.limit, {
                  [styles.errorLimit]: res?.value?.length >= limit
                })}
              >
                {res?.value?.length || 0}/{limit}
              </span>
            </span>
          </div>
          {(isReservedErrorSpace || errors[name]?.message) && (
            <div className={styles.textArea_errorMessage}>
              {errors[name]?.message || "​"}
            </div>
          )}
        </div>
      </label>
    );
  }
);
export default TextAreaField;
