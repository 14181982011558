import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import classNames from "classnames";

import styles from "./Profile.module.scss";

import BackButton from "../../components/shared/BackButton";
import Loader from "../../components/shared/Loading";
import Notification from "../../components/shared/Notification";
import PageHeader from "../../components/shared/PageHeader";
import Button, { ButtonType } from "../../components/shared/UiButton/Button";
import TextField from "../../components/shared/UiTextField/TextField";
import { UserRole } from "../../components/userManagement/api/users";
import { useActions } from "../../hooks/useActions";
import { axiosInstance } from "../../services/axios";
import {
  clientsSelector,
  profileSelector,
  singleClientSelector
} from "../../store/appStatic/selectors";
import { Client } from "../../types/client";
import { textParams } from "../../utils";
import { requiredValidaton } from "../../utils/validation";
import InfoItem from "./components/InfoItem";
import { ProfileFormItem } from "./Profile.types";

/**
 * Renders user profile page
 * @returns JSX element
 */
const Profile = (): JSX.Element => {
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const { t } = useTranslation("translation");

  const clients = useSelector<unknown, Client[]>(clientsSelector);
  const { data } = useSelector(singleClientSelector);
  const profile = useSelector(profileSelector);
  const { setProfileAction } = useActions();

  const [notification, setNotification] = useState<string | null>(null);

  const { control, formState, handleSubmit, reset } = useForm<ProfileFormItem>({
    defaultValues: { firstName: "", lastName: "" },
    mode: "all"
  });

  const isImpersonation =
    profile?.email?.toLowerCase() !== data?.profile?.email.toLowerCase();
  const isRegisteredUser = data?.profile?.status === "REGISTERED";
  const validateRequired = requiredValidaton(t("inputs.thisFieldIsRequired"));

  /**
   * Handles profile form submit event
   * @param values Profile form item
   */
  const onSubmit = async (values: ProfileFormItem): Promise<void> => {
    try {
      await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URI}api/users/${data?.profile?.id}`,
        values,
        { headers: { Authorization: `Bearer ${oktaAuth.getAccessToken()}` } }
      );
      // Refresh data in the store
      setProfileAction(history, undefined, false);
      setNotification(t("common.changesSaved"));
    } catch {
      setNotification(t("common.unknownError"));
    }
  };

  /**
   * Handles save button click event
   * @param e Mouse event
   */
  const onSaveClick = (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
  ): void => {
    if (formState.isSubmitting) {
      // Prevent additional submit if there is an active one
      e.preventDefault();
    }
  };

  useEffect(() => {
    reset({
      firstName: data?.profile?.firstName ?? "",
      lastName: data?.profile?.lastName ?? ""
    });
  }, [data]);

  if (!clients || !data || !profile) {
    return <Loader />;
  }

  return (
    <div className={classNames(styles.container, "container")}>
      <BackButton />
      <PageHeader variant="h1"> {t("profilePage.userProfile")}</PageHeader>

      {isRegisteredUser && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <InfoItem
            label={t("profilePage.email")}
            value={data.profile?.email}
            variant="control"
          />
          {/** Only superadmins can change passwords for other users in impersonation mode */}
          {(!isImpersonation || profile?.role === UserRole.SUPER_ADMIN) && (
            <InfoItem
              label={t("profilePage.password")}
              value={
                <Link className={styles.link} to="/change-password">
                  {t("profilePage.changePassword")}
                </Link>
              }
            />
          )}
          <InfoItem
            className={styles.infoItemWithControl}
            label={t("inputs.firstName")}
            value={
              <Controller
                control={control}
                name="firstName"
                render={({ field, formState }) => (
                  <TextField
                    {...field}
                    className={styles.textField}
                    errors={formState.errors}
                    isReservedErrorSpace
                    type="text"
                  />
                )}
                rules={{ validate: validateRequired }}
              />
            }
            variant="control"
          />
          <InfoItem
            className={styles.infoItemWithControl}
            label={t("inputs.lastName")}
            value={
              <Controller
                control={control}
                name="lastName"
                render={({ field, formState }) => (
                  <TextField
                    {...field}
                    className={styles.textField}
                    errors={formState.errors}
                    isReservedErrorSpace
                    type="text"
                  />
                )}
                rules={{ validate: validateRequired }}
              />
            }
            variant="control"
          />
          <Button
            className={styles.saveButton}
            loading={formState.isSubmitting}
            onClick={onSaveClick}
            type="submit"
            variant={ButtonType.Primary}
          >
            {t("buttons.saveChanges")}
          </Button>
        </form>
      )}
      {!isRegisteredUser && (
        <>
          <InfoItem
            label={t("profilePage.email")}
            value={data.profile?.email}
          />
          <InfoItem
            label={t("inputs.firstName")}
            value={data?.profile?.firstName}
          />
          <InfoItem
            label={t("inputs.lastName")}
            value={data.profile?.lastName}
          />
        </>
      )}
      <hr className={styles.hr} />
      <PageHeader variant="h2">
        {clients.length > 1
          ? t("profilePage.clientAccountInfo")
          : t("profilePage.accountInformation")}
      </PageHeader>
      <InfoItem label={t("profilePage.fullName")} value={data?.fullName} />
      <InfoItem
        label={t("profilePage.suisaIPINumber")}
        value={data?.suisaIpiNumber}
      />
      <InfoItem
        label={t("profilePage.affiliatedSociety")}
        value={data?.affiliatedSociety}
      />
      <InfoItem
        label={`${t("profilePage.address")} 1`}
        value={data?.address?.addressLine1}
      />
      <InfoItem
        label={`${t("profilePage.address")} 2`}
        value={data?.address?.addressLine2}
      />
      <InfoItem label={t("profilePage.cityTown")} value={data?.address?.city} />
      <InfoItem
        label={t("profilePage.State/Province")}
        value={data?.address?.state}
      />
      <InfoItem
        label={t("profilePage.zipCode")}
        value={data?.address?.zipCode}
      />
      <InfoItem
        label={t("profilePage.country")}
        value={data?.address?.country}
      />
      <div className={styles.contactUs}>
        {textParams(t("profilePage.somethingNotLookRight"))[0]}{" "}
        <Link className={styles.link} to="/contact-us">
          {textParams(t("profilePage.somethingNotLookRight"))[1]}.
        </Link>
      </div>
      <Notification
        isOpen={!!notification}
        onClose={() => setNotification(null)}
      >
        {notification}
      </Notification>
    </div>
  );
};

export default Profile;
