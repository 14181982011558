import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { UserRole } from "../../components/userManagement/api/users";
import { getUserLocalLanguage } from "../../i18n/utils";
import { request } from "../../services";
import {
  setErrorAction,
  setLoadingAction,
  setPayeeCodeAction,
  setProfileSuccessAction
} from "./actions";
import { AppStaticTypes, IGetLanguageAction, ISetProfileAction } from "./types";

const { REACT_APP_BASE_URI } = process.env;

let isFetched = true;

export function* getUsersProfileAsync({ payload }: ISetProfileAction): unknown {
  const shouldAutoSwitchLanguage = payload?.shouldAutoSwitchLanguage ?? true;
  const impersonateId = payload?.id || localStorage.getItem("impersonateId");
  const profile = yield select(state => state.appStaticReducer.profile);
  let fetchData: {
    role: string;
    id: string;
    settings: { preferableLanguage: string };
  } | null = null;

  try {
    if (isFetched) {
      isFetched = false;
      yield put(setLoadingAction(true));
      if (!profile || !impersonateId) {
        const { data } = yield call(
          request,
          "put",
          `${REACT_APP_BASE_URI}api/users/profile`
        );
        fetchData = data;
      }
      let res = null;
      if (
        ![UserRole.ADMIN, UserRole.SUPER_ADMIN].includes(
          fetchData?.role as UserRole
        ) ||
        impersonateId
      ) {
        try {
          res = yield call(
            request,
            "get",
            `${REACT_APP_BASE_URI}api/users/${impersonateId ||
              fetchData?.id}/clients`
          );
        } catch {
          yield put(setErrorAction(["not-found"]));
          payload.history.push("/not-found");
        }
      }
      const isFirstLoad =
        localStorage.getItem(
          "com.adobe.reactor.core.visitorTracking.pagesViewed"
        ) === "1";
      if (
        fetchData?.settings?.preferableLanguage &&
        isFirstLoad &&
        shouldAutoSwitchLanguage
      ) {
        localStorage.setItem(
          "i18nextLng",
          fetchData?.settings?.preferableLanguage
        );
      } else {
        localStorage.setItem(
          "i18nextLng",
          localStorage.getItem("i18nextLng") || getUserLocalLanguage()
        );
      }
      const payeeCode = localStorage.getItem("payeeCode");
      if (!payeeCode && res?.data?.clients) {
        const payeeCode = res?.data?.clients[0].payeeAccounts[0].payeeCode;
        yield put(setPayeeCodeAction(payeeCode));
      }
      yield put(
        setProfileSuccessAction({
          data: profile || fetchData,
          clients: res?.data?.clients || [],
          emailType: res?.data.emailType || [],
          preferableLanguage: res?.data?.preferableLanguage,
          email: res?.data?.email,
          profile: res?.data?.profile || null
        })
      );
    }
  } catch ({ response }) {
    const errors: any = response;
    const newError =
      errors?.data?.message || errors?.data?.debug || errors?.data;
    yield put(setErrorAction([newError]));
  } finally {
    isFetched = true;
    yield put(setLoadingAction(false));
  }
}

export function* getLanguageAsync({ payload }: IGetLanguageAction): unknown {
  if (localStorage.getItem("impersonateId")) {
    const res = yield call(
      request,
      "get",
      `${REACT_APP_BASE_URI}api/users/${payload ||
        localStorage.getItem("impersonateId")}/settings`
    );
    const ln = res?.data?.preferableLanguage || getUserLocalLanguage();
    localStorage.setItem("i18nextLng", ln);
    localStorage.setItem("impersonateLn", ln);
  }
}

export function* watchAppStaticRequest(): Generator {
  yield takeLatest(AppStaticTypes.GET_LANGUAGE_ACTION, getLanguageAsync);
  yield takeEvery(AppStaticTypes.PROFILE_ACTION, getUsersProfileAsync);
}

export function* handlePayeeCodeChange(): unknown {
  const payeeCode = yield select(state => state.appStaticReducer.payeeCode);
  localStorage.setItem("payeeCode", payeeCode);
}

export function* watchAppStaticPayeeCode(): Generator {
  yield takeLatest(AppStaticTypes.SET_PAYEE_CODE, handlePayeeCodeChange);
}
