import React, { forwardRef } from "react";
import cn from "classnames";

import styles from "./Checkbox.module.scss";
import { ReactComponent as CheckSquareGold } from "../../../icons/check_square_gold.svg";
import { ReactComponent as Square } from "../../../icons/square.svg";

export interface CheckBoxProps extends React.PropsWithChildren<unknown> {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  icon?: HTMLElement;
  isDark?: boolean;
  name?: string;
  label?: string;
  errors?: any;
  isReservedErrorSpace?: boolean;
}

const CheckBoxFiled: React.ForwardRefRenderFunction<
  HTMLInputElement,
  CheckBoxProps
> = (props, ref) => {
  const {
    name = "",
    className,
    label,
    errors = {},
    onChange,
    isDark = true,
    isReservedErrorSpace = false,
    ...res
  } = props;

  return (
    <label
      htmlFor={name}
      className={cn(className, styles.checkbox_container, {
        [styles.dark]: isDark
      })}
    >
      <input
        {...res}
        ref={ref}
        type="checkbox"
        id={name}
        name={name}
        className={cn(styles.checkbox, {
          [styles.checkbox_inValid]: errors[name],
          [styles.dark]: isDark
        })}
        onChange={onChange}
      />
      <span className={cn(styles.checkbox_marker, "checkbox_marker")}>
        <CheckSquareGold className={styles.checkbox_marker_check} />
        <Square className={styles.checkbox_marker_square} />
      </span>
      {label && (
        <span className={cn(styles.checkbox_label, "checkboxField")}>
          {label}
        </span>
      )}
      {(isReservedErrorSpace || errors[name]?.message) && (
        <div className={styles.checkbox_errorMessage}>
          {errors[name]?.message || "​"}
        </div>
      )}
    </label>
  );
};

export default forwardRef(CheckBoxFiled);
