import { createSelector } from "reselect";

import { Client } from "../../types/client";

const appStaticSelector = (state: any) => state.appStaticReducer;

const countrySelector = createSelector(appStaticSelector, appStaticReducer =>
  appStaticReducer.country.map((el: { name: string; code: string }) => ({
    label: el.name,
    value: el.code
  }))
);

const contactUsQuestionsSelector = createSelector(
  appStaticSelector,
  appStaticReducer => appStaticReducer.contactUsQuestions
);

const languageSelector = createSelector(appStaticSelector, (appStaticReducer): {
  label: string;
  value: string;
}[] =>
  appStaticReducer.languages.map((el: { name: string; code: string }) => ({
    label: el.name,
    value: el.code
  }))
);

const profileSelector = createSelector(
  appStaticSelector,
  appStaticReducer => appStaticReducer.profile
);

const profileBeSelector = createSelector(
  appStaticSelector,
  appStaticReducer => appStaticReducer.profileBe
);

const loadingSelector = createSelector(
  appStaticSelector,
  appStaticReducer => appStaticReducer.loading
);

const errorsSelector = createSelector(
  appStaticSelector,
  appStaticReducer => appStaticReducer.errors
);
const singleClientSelector = createSelector(
  appStaticSelector,
  (appStaticReducer): { data?: Client | null } => appStaticReducer.singleClient
);

const clientsSelector = createSelector(
  appStaticSelector,
  appStaticReducer => appStaticReducer.clients
);
const countryCodeSelector = createSelector(
  appStaticSelector,
  appStaticReducer => appStaticReducer.countryCode
);
const impersonateLnSelector = createSelector(
  appStaticSelector,
  appStaticReducer => appStaticReducer.impersonateLn
);

const payeeCodeSelector = createSelector(
  appStaticSelector,
  appStaticReducer => appStaticReducer.payeeCode
);

const preselectedPayeeCodeSelector = createSelector(
  appStaticSelector,
  appStaticReducer => appStaticReducer.preselectedPayeeCode
);

export {
  clientsSelector,
  contactUsQuestionsSelector,
  countryCodeSelector,
  countrySelector,
  errorsSelector,
  impersonateLnSelector,
  languageSelector,
  loadingSelector,
  payeeCodeSelector,
  preselectedPayeeCodeSelector,
  profileBeSelector,
  profileSelector,
  singleClientSelector
};
