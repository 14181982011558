import { useEffect, useRef } from "react";

import styles from "./Notification.module.scss";

import { CLOSE_DELAY } from "./Notification.constants";
import { NotificationProps } from "./Notification.types";

/**
 * Renders notification popup
 * @param props React component properties
 * @returns JSX element
 */
const Notification = (props: NotificationProps): JSX.Element => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (props.isOpen) {
      dialogRef.current?.showModal();
      const timeoutId = setTimeout(props.onClose, CLOSE_DELAY);
      return () => {
        clearTimeout(timeoutId);
      };
    }
    dialogRef.current?.close();
  }, [props.isOpen]);

  return (
    <dialog className={styles.dialog} onClick={props.onClose} ref={dialogRef}>
      <div className={styles.dialogContent}>
        <div>{props.children}</div>
      </div>
    </dialog>
  );
};

export default Notification;
