import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import styles from "./TextField.module.scss";
import { ReactComponent as EyeGoldIcon } from "../../../icons/close_white_eye.svg";
import { ReactComponent as EyeWhiteIcon } from "../../../icons/show_white_eye.svg";

export interface TextFieldProps extends React.PropsWithChildren<unknown> {
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  icon?: React.ReactNode;
  name?: string;
  label?: string;
  errors?: any;
  isDark?: boolean;
  isReservedErrorSpace?: boolean;
  [key: string]: any;
}

const TextField: React.FC<TextFieldProps> = React.forwardRef(function TextField(
  props,
  ref: any
) {
  const {
    name = "",
    type,
    className,
    label,
    errors = {},
    icon,
    readOnly,
    isReservedErrorSpace = false,
    ...res
  } = props;
  const [passwordShown, setpasswordShown] = useState<boolean>(false);
  const toggleType = () => {
    setpasswordShown(!passwordShown);
  };
  const { t } = useTranslation("translation");
  return (
    <label
      htmlFor={name}
      className={`${styles.textField_container} ${className}`}
    >
      {!!label && (
        <div className={styles.textField_head}>
          <div className={cn(styles.textField_label, "inputLabel")}>
            {label}
          </div>
          {type === "password" && (
            <div
              className={styles.textField_toggle}
              onClick={toggleType}
              data-testid="el"
            >
              {passwordShown ? (
                <EyeGoldIcon
                  className={`${styles.textField_eye} ${styles.textField_eye_close}`}
                />
              ) : (
                <EyeWhiteIcon className={styles.textField_eye} />
              )}
              <span
                className={`${styles.textField_showText} ${
                  passwordShown ? "text-wcm-accent" : ""
                }`}
              >
                {!passwordShown ? t(`inputs.show`) : t(`inputs.hide`)}
              </span>
            </div>
          )}
        </div>
      )}
      <div>
        <div
          className={cn(styles.textField_wrapper, {
            [styles.disabled]: props.readOnly || props.disabled,
            [styles.textField_inValid]: errors[name]
          })}
        >
          {icon}
          <input
            placeholder=" "
            {...res}
            ref={ref || null}
            name={name}
            id={name}
            type={
              type !== "password" ? type : passwordShown ? "text" : "password"
            }
            aria-invalid={errors[name] ? "true" : "false"}
            className={`${styles.textField}`}
            disabled={props.disabled || readOnly}
          />
        </div>
        {(isReservedErrorSpace || errors[name]?.message) && (
          <div className={styles.textField_errorMessage}>
            {errors[name]?.message || "​"}
          </div>
        )}
      </div>
    </label>
  );
});
export default TextField;
