import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import cn from "classnames";

import styles from "./LanguageModal.module.scss";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";
import { ReactComponent as Glob } from "../../../../icons/white_glogus.svg";

import { useActions } from "../../../../hooks/useActions";
import { languageSelector } from "../../../../store/appStatic/selectors";
import { dealsModalSelector } from "../../../../store/dealsStore/selectors";
import { textParams } from "../../../../utils";
import Notification from "../../Notification";
import Button from "../../UiButton";
import { ButtonType } from "../../UiButton/Button";
import { LanguageModalProps } from "./LanguageModal.types";

const LanguageModal = (props: LanguageModalProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const modal = useSelector(dealsModalSelector);
  const { data } = modal;
  const languages = useSelector(languageSelector);
  const { SetOpenModal } = useActions();
  const lang =
    data && languages?.find((el: any) => el.value === data.value)?.label;
  const toggleModal = useCallback(() => {
    SetOpenModal({ isOpen: false, type: "", data: data });
  }, [SetOpenModal]);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    e.preventDefault();
    setIsLoading(true);
    await props.onSubmit(data);
    setIsLoading(false);
  };

  const changedLang: any = languages &&
    data &&
    languages?.find((el: any) => el.value === data.value) && {
      label: languages?.find(l => l.value === data.value)?.label,
      value: languages?.find(l => l.value === data.value)?.value
    };

  return (
    <div className={styles.SetUserControlModal}>
      <Modal
        ariaHideApp={false}
        isOpen={modal.isOpen && modal.type === "langModal"}
        onRequestClose={toggleModal}
        className={cn(styles.mymodal)}
        overlayClassName={styles.myoverlay}
      >
        <>
          <CloseIcon className={styles.closeIcon} onClick={toggleModal} />
          <div className={styles.buttonWrap} />
        </>
        <h2 className={cn(styles.title)}>{t(`profilePage.changeLanguage`)}</h2>
        <div className={cn(styles.description)}>
          {textParams(t(`profilePage.changeLanguageText`))[0]}{" "}
          <strong>{lang}</strong>
          {textParams(t(`profilePage.changeLanguageText`))[2]}{" "}
        </div>
        <div className={cn(styles.buttons)}>
          <Button
            variant={ButtonType.Text}
            className={cn(styles.btn)}
            onClick={toggleModal}
          >
            {t(`buttons.cancel`)}
          </Button>
          <Button
            variant={ButtonType.Primary}
            className={cn(styles.btn, styles.btn_primary)}
            onClick={onSubmit}
            loading={isLoading}
            disabled={isLoading}
          >
            {t(`buttons.save`)}
          </Button>
        </div>
      </Modal>
      <Notification
        isOpen={modal.isOpen && modal.type === "successLang"}
        onClose={toggleModal}
      >
        <p className={cn(styles.success)}>
          <Glob />
          <span>
            {textParams(t(`profilePage.changeLanguageSuccess`))[0]}{" "}
            {changedLang && changedLang.label}
          </span>
        </p>
      </Notification>
    </div>
  );
};
export default LanguageModal;
